<template>
    <div class="nav-ma">
        <div class="mobile-nav-list">
            <a-icon class="mobile-menu colorfff flex x-right y-center" style="height:100%;" type="bars" :style="{ fontSize: '20px' }" @click="handleMenuList" />
        </div>
        <div v-if="specialContent.banner" class="banner-ma" :style="{backgroundImage:'url('+specialContent.banner+')'}"></div>
        <div v-else class="banner-ma0"></div>
        <div v-if="navList.length > 1" class="container-list" :style="{backgroundColor: specialContent.other?.color_nav}">
            <div class="container flex y-center">
                <div class="flex x-left y-center nav-list">
                    <template v-for="(item, index) in navList">
                        <div :key="index" class="nav-item" v-if="item.link_type_id != 99">
                            <router-link :target="item.target" :class="{ 'active': path.indexOf(item.path) != -1 }" :style="{color: path.indexOf(item.path) != -1 ? specialContent.other?.color_nav_font_hover : specialContent.other?.color_nav_font,backgroundColor:path.indexOf(item.path) != -1 ? specialContent.other?.color_nav_current : 'transparent'}"
                            class="nav-item-text" :to="{ path: item.path }">{{ item.title }}</router-link>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <!-- 移动端列表icon -->
        <a-drawer class="mobile-tab" placement="left" :maskClosable='true' :closable="false" :visible="visible" @close="visible = false">
            <div v-for="(item, index) in navList" :key="index" class="nav-item">
                <router-link  v-if="item.link_type_id != 99" :target="item.target" :class="{ 'active': path.indexOf(item.path) != -1 }" :style="{color: path.indexOf(item.path) != -1 ? specialContent.other?.color_nav_font_hover : '#333',backgroundColor:path.indexOf(item.path) != -1 ? specialContent.other?.color_nav_current : 'transparent'}"
                            class="nav-item-text" :to="{ path: item.path }">{{ item.title }}</router-link>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import { committee_id } from '@/config';
export default {
    name: 'layoutHeaderMa',
    components: {

    },
    props: {
        specialInfo:{
            type:Object,
            default: () => {}
        }
    },
    data() {
        return {
            topic_id:this.$route.params.id,
            visible:false,
            path: this.$route.fullPath,
            navList: [],
            specialContent:{},
        }
    },
    created() {
       
    },
    mounted() {
        if(this.specialInfo){
            let specialContent = this.specialInfo
            this.specialContent = specialContent
        }
        if(this.$store.state?.navList?.length > 1){
            this.navList = this.$store.state.navList
        }else{
            this.getNavList()
        }
        
    },
    destroyed () {
        
    },
    methods: {
        handleMenuList() {
            this.visible = true
        },
        sortNum(ary, key) {
			return ary.sort(function (a, b) {
			    let x = a[key]
			    let y = b[key]
			    return ((x < y) ? -1 : (x > y) ? 1 : 0)
			})
		},
        getNavList(){
            this.request.post('TopicNavList', { limit: '10', topic_id: this.topic_id, page: 1,committee_id }).then(res => {
                let navList = res.data.list
                navList.unshift({
                    title:'首页',
                    is_show: "1",
                    path: '/special-ma/index/'+this.$route.params.id
                })
                navList.forEach((item,index)=>{
                    let linkTypeId = item.link_type_id
                    switch (linkTypeId) {
                        case '1': //资讯
                            item.path = '/special-ma/infomation-list/'+this.$route.params.id
                            break;
                        case '2': //视频
                            item.path = '/special-ma/video-list/'+this.$route.params.id
                            break;
                        case '3': //指南
                            item.path = '/special-ma/guide-list/'+this.$route.params.id
                            break;
                        case '4': //期刊
                            item.path = '/special-ma/periodical-list/'+this.$route.params.id
                            break;
                        case '5': //会议
                            item.path = '/special-ma/meeting-list/'+this.$route.params.id
                            break;
                        case '6': //专家
                            item.path = '/special-ma/expert-list/'+this.$route.params.id
                            break;
                        case '7': //机构
                            item.path = '/special-ma/org-list/'+this.$route.params.id
                            break;
                        case '8': //论文
                            item.path = '/special-ma/article-list/'+this.$route.params.id
                            break;
                        default:
                            // console.log('000')
                    };
                })
                // this.navList = this.sortNum(navList,'pre_sort_num')
                this.navList = navList
                this.$store.commit('updateState',{navList:this.navList});
            })
        }
    },
}
</script>
<style lang="less" scoped>
.nav-ma {

    .container-list {
        background-color: @theme;
    }

    .container {
        .nav-list {
            height: 50px;
        }
        
    }
    .banner-ma {
        width: 100%;
        height: 300px;
        background-position: center center;
        background-repeat: no-repeat;
        // background: url('~@/assets/images/banner-ma.jpg') center center no-repeat;
        background-size: cover;
    }
    .banner-ma0{
        height: 0;
    }
    .mobile-nav-list{
        display: none;
    }

    .nav-item {
        display: block;
        font-size: .48rem;
        height: 50px;
        line-height: 50px;
        text-align: justify;
        color: #fff;

        &-text {
            display: block;
            font-size: 18px;
            height: 50px;
            line-height: 50px;
            text-align: justify;
            color: #FFFFFF;
            width: 100%;
            padding: 0 15px;

            &:after {
                content: '';
                width: 100%;
                display: inline-block;
                overflow: hidden;
                height: 0;
            }

            &.active,
            &:hover {
                color: #FFA45D;
            }
        }
    }
}

@media screen and (max-width:768px) {
    .nav-ma {
        height: auto;
        background: #fff;

        .container {
            width: 100%;
            display: block;
            padding: 0;
        }
        .container-list{
            display: none;
            
        }
        .mobile-nav-list{
            width: 100%;
            display: block;
            height: 50px;
            padding: 0 10px;
            box-sizing: border-box;
            position: fixed;
            top:0;
            z-index: 9999;
            background: linear-gradient(-45deg, #813ae4 ,@theme);
        }
        .nav-list {
            display: none;
            height: auto!important;
            flex-wrap: wrap;
            padding: 0 10px;
            margin-top: 50px;
            .nav-item{
                width: calc(25% - 10px);
                margin-left: 10px;
            }
        }
        
        .banner-ma {
            height: auto;
            padding-top: 15%;
            margin-top: 50px;
        }
        .banner-ma0{
            height: 0;
            margin-top: 50px;
        }
    }
    .mobile-tab{
        z-index: 99999999;
        top:50px;
        height:calc(100% - 50px);
        /deep/.ant-drawer-body{
            padding: 0;
        }
        /deep/.ant-drawer-content-wrapper{
            width: 200px!important;
        }
        .nav-item{
            height: 40px;
            background: #fff;
            text-align: center;
            &-text{
                color: #333;
            }
            a{
                box-sizing: border-box;
                height: 40px;
                display: block;
                padding: 10px 0;
            }
            .active{
                background: @theme;
                color: #fff;
            }
        }
    }
}</style>