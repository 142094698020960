<template>
    <div class="hot-topic" :style="{ width: containerWidth1}">
        <router-link :to="{path:path,query:{id:item.id,type:type}}">
        <!-- 会议报道/会议征文三张图 -->
        <div v-if="item.information_type == 2" class="pb25 report-three-list">
            <a class="color333 fs16 row-1 t-l">
                <span>{{ item.news_title }}</span>
            </a>
            <div class="mt10 report-img flex" v-if="item.images.length">
                <div class="report-img-box report-img-box-th" v-for="(it,idx) in item.images" v-if="idx<3"  :key="idx">
                    <img class="report-inner-img" :src="it | urlFilter(250)"
                        :style="{ width: width2, height: height ? height : (parseInt(width2) / 16 * 9 + 'px') }" alt="">
                    <img class="report-inner-img-blur" :src="it | urlFilter(250)"
                        :style="{ width: width2, height: height ? height : (parseInt(width2) / 16 * 9 + 'px') }" alt="">
                </div>
            </div>
            <template v-if="showBottom">
                <div class="flex mt10">
                    <div class="flex y-center">
                        <!-- <img class="report-icon" src="~@/assets/images/footer-qr3.png" alt=""> -->
                        <span v-if="item.source" style="max-width:40%;" class="color999 fs14 ml5 row-1 t-l mr20">{{item.source}}</span>
                        <span class="color999 fs14 mr15" v-if="item.browsin">{{item.browsing}}阅读</span>
                        <span class="color999 fs14">{{item.create_time}}</span>
                    </div>
                    <div class="flex">
                        <div class="color999" v-if="item.zan">
                            <i class="med med-dianzan_huaban"></i>
                            <span class="fs12" style="margin-left:2px;">{{item.zan}}</span>
                        </div>
                        <div class="ml10 color999" v-if="item.collect">
                            <i class="med med-shoucang"></i>
                            <span class="fs12" style="margin-left:2px;">{{item.collect}}</span>
                        </div>
                    </div>
                </div>
            </template>
            <slot name="report-bottom" v-else></slot>
        </div>
        <!-- <div v-if="item.information_type == 6" class="mt20 pb20">
                <img class="hot-topic-advertising" src="https://files.sciconf.cn/meeting/2021/12089/image/20210713/2021071315081110238569147.jpg!scaling50" alt="">
            </div> -->
        <!-- 重要通知/重要公示/最新资讯一张图 -->
        <div v-else class="pb20 report-one-list">
            <div class="report-list">
                <div class="flex x-left">
                    <div class="report-img-box mr20" ref="box" v-if="item.news_pic">
                        <img class="report-inner-img" :style="{width:width1,height:height?height:(parseInt(width1)/16*9+'px')}"
                            :src="item.news_pic  | urlFilter(250)" alt="" />
                        <img class="report-inner-img-blur" :style="{width:width1,height:height?height:(parseInt(width1)/16*9+'px')}"
                            :src="item.news_pic  | urlFilter(250)" alt="" />
                    </div>
                    <div class="flex-column flex-item x-between">
                        <a class="color333 fs16 row-2 t-l">
                            <span class="hot-topic-item-title">{{item.news_title}}</span>
                        </a>
                        <template v-if="showBottom">
                            <div class="flex mt10 bottom-top"  :class="[{ 'mt25' : !item.news_pic }]">
                                <div class="flex y-center x-left" style="width:100%;">
                                    <!-- <img class="report-icon" src="~@/assets/images/footer-qr3.png" alt=""> -->
                                    <span v-if="item.source" style="max-width: 40%;" class="topic-title color999 fs14 row-1 t-l mr20 ">{{item.source}}</span>
                                    <span class="color999 fs14 mr15" v-if="item.browsing">{{item.browsing}}阅读</span>
                                    <span class="color999 fs14">{{item.create_time}}</span>
                                </div>
                                <div class="flex">
                                    <div class="color999" v-if="item.zan">
                                        <i class="med med-dianzan_huaban"></i>
                                        <span class="fs12" style="margin-left:2px;">{{item.zan}}</span>
                                    </div>
                                    <div class="ml10 color999" v-if="item.collect">
                                        <i class="med med-shoucang"></i>
                                        <span class="fs12" style="margin-left:2px;">{{item.collect}}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <slot name="report-bottom" v-else></slot>
                    </div>
                </div>
            </div>
        </div>
        </router-link>
        <!-- </div> -->
    </div>
</template>

<script>
export default {
    name: "hot-topic",
    props: {
        path:{
            type:String,
            default:''
        },
        type:{
            type:String,
            default:''
        },
        containerWidth:{
            type: String,
            default: '601px',
        },
        width: {
            type: String,
            default: '189px',
        },
		widthNew: {
            type: String,
            default: '',
        },
        height: {
            type: [Number, String],
            default: 0,
        },
        showBottom:{
            type: Boolean,
            default: true,
        },
        item: {
            type: Object,
            default: () => {
                // id: 1, 
                // type: 1, 
                // typename: "会议报道", 
                // title: "第十二届北京睡眠高峰会议暨第二届中国发作性睡病及中枢性第二届中国",
                // img: "https://files.sciconf.cn/meeting/2021/12089/image/20210713/2021071315081110238569147.jpg!scaling50",
            },
        }
    },
    data() {
        return {
            containerWidth1:'',
            width1:'',
            width2:'',
        }
    },
    components: {
    },
    methods: {

    },
    mounted(){
        // 取屏幕宽度的1/2或1/3适配移动端排列
        this.containerWidth1 = this.containerWidth
        this.width1 = this.width
        this.width2 = this.width
        let clientWidth = document.body.clientWidth
        if(clientWidth <= 550){
            this.containerWidth1 = clientWidth + 'px'
        }
        if(clientWidth <= 768){
            this.width1 = clientWidth/3 + 'px'
            if(this.widthNew == '160px'){
                this.width1 = '160px'
            }
        }
        if(clientWidth <= 768){
            this.width2 = (clientWidth - 40) /3 + 'px'
        }
        
    },
};
</script>

<style scoped lang="less">
.hot-topic {   
    width: 100%;
    .report-img-box {
        position: relative;
        overflow: hidden;
        .report-inner-img {
            width: 187px;
            height: 105px;
            object-fit: contain;
            object-position: center center;
            position: relative;
            z-index:2;
        } 
        .report-inner-img-blur{
            position: absolute;
            left:0;
            top:0;
            filter:blur(5px) url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxmaWx0ZXIgaWQ9ImZpbHRlci1wcmltaXRpdmVzIj48ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSIzIiAvPjxmZUNvbG9yTWF0cml4IHR5cGU9Imh1ZVJvdGF0ZSIgdmFsdWVzPSIyNzAiLz48ZmVDb2xvck1hdHJpeCB0eXBlPSJzYXR1cmF0ZSIgdmFsdWVzPSIwLjc1Ii8+PGZlQmxlbmQgbW9kZT0ibXVsdGlwbHkiLz48L2ZpbHRlcj48L2RlZnM+PC9zdmc+DQo=')
        }
    }

    .report-icon {
        width: 20px;
        height: 20px;
    }

    .hot-topic-advertising {
        width: 600px;
        height: 146px;
    }
}
@media screen and (max-width:768px){
    .hot-topic{
        width: 100%!important;
        .report-one-list,.report-three-list{
            padding-bottom: 0;
        }
        .report-img{
            display: flex;
            justify-content: space-between;
        }
        .report-img-box{
            margin-right: 10px;
        }
        .report-img-box-th{
            margin-right: 0;
        }
    }
}
@media screen and (max-width:550px){
    .hot-topic{
        .bottom-top{
            margin-top: 0;
        }
        .topic-title{
            max-width:30%!important;
            margin-right: 5px;
        }
    }
}
</style>
