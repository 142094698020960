<template>
    <div :style="{width:width}" @click="handleNavigateEvent(item.url,item.id,item)" :class="['ml'+gap,'mt'+gap]" class="vertical-media-item">
      <div :style="{width:'100%'}" class="v-item-img">
        <img v-if="item.img && item.img.indexOf('250') != -1" :src="item.img" alt="" />
        <img v-else :src="item.img | urlFilter(250)" alt="" />
        <div v-if="type == 1" :title="item.title" class="row-1 colorfff v-img-title fs12 pl10 pr10 t-l">{{item.title}}</div>
      </div>
      <div class="v-info-list t-l pt10 pb10 pl10 pr10">
        <div v-if="type == 2" style="height:50px;line-height:1.5" :title="item.title" class="row-2 mb5 strong color333 fs16">{{item.title}}</div>
        <div class="row-1">
          <span clang="fs14 strong color333" v-if="item.name">{{item.name}}</span>
          <span class="fs12 color999 pl5" v-if="item.department">{{item.department}}</span>
        </div>
        <!-- <div class="fs12 color666 pt5 row-1" v-if="item.org" :style="{width:width}">{{item.org}}</div> -->
        <div class="fs12 color666 pt5 row-1" v-if="item.org">{{item.org}}</div>
      </div>
      <slot name="vertical-bottom"></slot>
    </div>
</template>

<script>
export default {
    name: "vertical-media-item",
    props: {
        type:{
          type:[Number,String],
          default:1,
        },
        jumpType:{
          type:String,
          default:'', //ma为综合专题相关页面
        },
        width: {
            type: String,
            default: '320px',
        },
        height: {
            type: [Number, String],
            default: 0,
        },
        gap: {
            type: String,
            default: '20',
        },
        url:{
            type: String,
            default: '',
        },
        id:{
            type: String,
            default: '',
        },
        item: {
            type: Object,
            defalut: () => {
              //   id:1,
              //   img:'',
              //   title:',
              //   name:'',  
              //   department:'',
              //   org:'',
              //   url:'',
            },
        },
    },
    data(){
        return {
        }
    },
    methods:{
        handleNavigateEvent(url,id,item){
            if(url && this.jumpType != 'ma'){
                router.push({
                    path:url,
                    query:{
                        id,
                    }
                })
            }else{
                this.$emit('clickEvent',item);
            }
        }
    },
    mounted(){
    },
};
</script>

<style scoped lang="less">
.vertical-media-item {
    color: #333;
    background: #f9f9f9;
    display: block;
    max-width: 100%;
    cursor: pointer;
    .v-info-list{
        min-height: 64px;
    }
    .v-item-img {
        padding-top:calc(100% / 16 * 9);
        transition: all 0.3s linear;
        position: relative;
        .v-img-title {
            position: absolute;
            bottom: 0;
            background: rgba(0, 0, 0, 0.3);
            width: 100%;
            height: 35px;
            line-height: 35px;
            box-sizing: border-box;
        }
        &:hover {
            transform: scale(1.02);
        }
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top:0;
            left:0;
        }
    }
}
@media screen and (max-width:768px){
    .vertical-media-item{
        margin-top: 0;
        margin-bottom: 10px;
    }
}
</style>
