<template>
    <div class="layout flex flex-column">
        <layout-header-ma v-if="specialInfo" v-on="$listeners" :specialInfo="specialInfo"></layout-header-ma>
        <!-- <layout-nav class="nav-show"></layout-nav> -->
        <div class="flex-item">
            <slot></slot>
        </div>
        <layout-footer></layout-footer>
    </div>
</template>

<script>
import layoutHeaderMa from '@/components/layout/layout-header-ma.vue';
import layoutFooter from '@/components/layout/layout-footer.vue';
import storage from 'store'
export default {
    name: 'layout',
    components: {
        layoutHeaderMa,
        layoutFooter
    },
    data(){
        return {
            // topic_id:this.$route.params.id,
            specialInfo:null,
        }
    },
    methods:{
        getTopicDetail(){
            this.request.post('TopicDetail', {topic_id: this.$route.params.id}).then(res => {
                this.$store.commit('updateState',{specialInfo:res.data});
                res?.data?.other && (res.data.other = JSON.parse(res.data?.other))
                this.specialInfo = res.data
                document.title = res.data?.title
            })
        },
    },
    created() {
        if(this.$store.state?.specialInfo?.id == this.$route.params.id){
            this.specialInfo = this.$store.state.specialInfo
            document.title = this.$store.state.specialInfo?.title
        }else{
            this.getTopicDetail()
        }
    },
}
</script>
<style>
@media screen and (max-width:768px){
    body{
        padding: 0;
    }
}
</style>
<style lang="less" scoped>
.layout{
    min-height:100vh;
}
@media screen and (max-width:768px){
    .layout{
        .nav-show{
            display: none;
        }
    }
}
</style>
