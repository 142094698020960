import { render, staticRenderFns } from "./hot-topic-item.vue?vue&type=template&id=e7598a62&scoped=true&"
import script from "./hot-topic-item.vue?vue&type=script&lang=js&"
export * from "./hot-topic-item.vue?vue&type=script&lang=js&"
import style0 from "./hot-topic-item.vue?vue&type=style&index=0&id=e7598a62&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7598a62",
  null
  
)

export default component.exports